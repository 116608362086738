import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Input, Label, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import ModalEditSection from "../../components/education/PlaningEducation/modal/ModalEditSection";
import ModalDeleteModule from "../../components/education/PlaningEducation/modal/ModalDeleteModule";
import ModalCreateModule from "../../components/education/PlaningEducation/modal/ModalCreateModule";
import NotificationShow from "../../components/widgets/NotificationShow";

interface IContent {
    id: number;
    type: 'text' | 'video' | 'image' | 'heading' | 'pdf' | 'presentation';
    value: string | File;
}

interface ISection {
    id: number;
    title: string;
    content: IContent[];
    moduleId: number;
}

interface IModule {
    id: number;
    title: string;
    displayId: number;
}

type TableRow = IModule | ISection;

const CreateEducationContent: React.FC = () => {
    const { educationId } = useParams<{ educationId: string }>();
    const location = useLocation();
    const { educationTitle } = (location.state as { educationTitle: string }) || { educationTitle: 'Неизвестное обучение' };

    const [modules, setModules] = useState<IModule[]>([]);
    const [sections, setSections] = useState<ISection[]>([]);
    const [filteredData, setFilteredData] = useState<TableRow[]>([]);
    const [search, setSearch] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateModuleModalOpen, setIsCreateModuleModalOpen] = useState(false);
    const [sectionToEdit, setSectionToEdit] = useState<ISection | null>(null);
    const [nextModuleId, setNextModuleId] = useState(1);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [moduleToDelete, setModuleToDelete] = useState<IModule | null>(null);

    useEffect(() => {
        let filteredModules = [...modules];

        if (search) {
            filteredModules = filteredModules.filter(module =>
                module.title.toLowerCase().includes(search.toLowerCase())
            );
        }

        const tableData: TableRow[] = [];
        filteredModules.forEach(module => {
            tableData.push(module);
            const moduleSections = sections.filter(section => section.moduleId === module.id);
            tableData.push(...moduleSections);
        });

        setFilteredData(tableData);
    }, [search, modules, sections]);

    const handleAddModule = (title: string) => {
        const newModule: IModule = {
            id: Date.now(),
            title,
            displayId: nextModuleId,
        };
        setModules([...modules, newModule]);
        setNextModuleId(nextModuleId + 1);
    };

    const handleEditModule = (moduleId: number, newTitle: string) => {
        setModules(modules.map(module =>
            module.id === moduleId ? { ...module, title: newTitle } : module
        ));
    };

    const handleDeleteModule = () => {
        if (moduleToDelete) {
            setModules(modules.filter(module => module.id !== moduleToDelete.id));
            setSections(sections.filter(section => section.moduleId !== moduleToDelete.id));
            setModules(prevModules => prevModules.map((module, index) => ({
                ...module,
                displayId: index + 1,
            })));
            setNextModuleId(modules.length);
            setModuleToDelete(null);
            setIsDeleteModalOpen(false);
        }
    };

    const handleAddSection = (moduleId: number) => {
        const newSection: ISection = {
            id: Date.now(),
            title: `Раздел ${sections.filter(s => s.moduleId === moduleId).length + 1}`,
            content: [],
            moduleId,
        };
        setSections([...sections, newSection]);
    };

    const handleEditSection = (section: ISection) => {
        setSectionToEdit(section);
        setIsModalOpen(true);
    };

    const handleDeleteSection = (sectionId: number) => {
        setSections(sections.filter(section => section.id !== sectionId));
    };

    const handleSaveSection = (updatedSection: ISection) => {
        setSections(sections.map(section =>
            section.id === updatedSection.id ? updatedSection : section
        ));
        setIsModalOpen(false);
        setSectionToEdit(null);
    };

    const handleSaveAll = () => {
        console.log("Сохранение всей информации об обучении...");
    };

    const handleOpenDeleteModal = (module: IModule) => {
        setModuleToDelete(module);
        setIsDeleteModalOpen(true);
    };

    const columns = [
        {
            name: <span className="font-weight-bold accessibility_size_table">№</span>,
            cell: (row: TableRow) => {
                if ('moduleId' in row) {
                    return '';
                }
                return (row as IModule).displayId;
            },
            width: '60px',
            style: {
                minWidth: '60px',
                maxWidth: '60px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
        {
            name: <span className="font-weight-bold accessibility_size_table">Название</span>,
            cell: (row: TableRow) => {
                if ('moduleId' in row) {
                    return <span className="accessibility_size_table" style={{ marginLeft: '40px' }}>{row.title}</span>;
                }
                return (
                    <Input
                        type="text"
                        value={row.title}
                        onChange={(e) => handleEditModule(row.id, e.target.value)}
                        placeholder="Введите название модуля"
                        className="accessibility_size_table"
                    />
                );
            },
            wrap: true,
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: TableRow) => {
                if ('moduleId' in row) {
                    return (
                        <i
                            className="bi bi-pencil fs-5 text-black cursor-pointer"
                            onClick={() => handleEditSection(row as ISection)}
                            title="Редактировать раздел"
                        />
                    );
                }
                return (
                    <i
                        className="bi bi-plus-lg fs-5 text-black cursor-pointer"
                        onClick={() => handleAddSection(row.id)}
                        title="Добавить раздел"
                    />
                );
            },
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: TableRow) => {
                if ('moduleId' in row) {
                    return (
                        <i
                            className="bi bi-trash fs-5 text-danger cursor-pointer"
                            onClick={() => handleDeleteSection(row.id)}
                            title="Удалить раздел"
                        />
                    );
                }
                return (
                    <i
                        className="bi bi-trash fs-5 text-danger cursor-pointer"
                        onClick={() => handleOpenDeleteModal(row as IModule)}
                        title="Удалить модуль"
                    />
                );
            },
            width: '80px',
        },
    ];

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Создание контента для обучения</title>
            </Helmet>
            <Container fluid>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">Создание контента для обучения: {educationTitle}</h4>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col md={3}>
                                <Label>Поиск по названию:</Label>
                                <Input
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Введите название модуля"
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        <div>
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noDataComponent="Нет модулей для отображения"
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Модулей на странице:',
                                    rangeSeparatorText: 'из',
                                }}
                                fixedHeader
                                highlightOnHover
                                customStyles={{
                                    headCells: {
                                        style: {
                                            minWidth: '60px',
                                            maxWidth: '60px',
                                        },
                                    },
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5",
                                        },
                                    },
                                }}
                                conditionalRowStyles={[
                                    {
                                        when: (row: TableRow) => !('moduleId' in row),
                                        style: {
                                            backgroundColor: '#f5f5f5',
                                            fontWeight: 'bold',
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-between align-items-center text-muted">
                        <div>
                            Кол-во модулей: {modules.length}
                        </div>
                        <div>
                            <Button
                                color="primary"
                                onClick={() => setIsCreateModuleModalOpen(true)}
                                className="me-2"
                            >
                                Добавить модуль
                            </Button>
                            <Button color="primary" onClick={handleSaveAll}>
                                Сохранить
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Container>
            {sectionToEdit && (
                <ModalEditSection
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(false)}
                    section={sectionToEdit}
                    onSave={handleSaveSection}
                />
            )}
            <ModalCreateModule
                isOpen={isCreateModuleModalOpen}
                toggle={() => setIsCreateModuleModalOpen(false)}
                onSave={handleAddModule}
            />
            {moduleToDelete && (
                <ModalDeleteModule
                    isOpen={isDeleteModalOpen}
                    toggle={() => setIsDeleteModalOpen(false)}
                    onDelete={handleDeleteModule}
                    moduleTitle={moduleToDelete.title}
                />
            )}
            <NotificationShow />
        </div>
    );
};

export default CreateEducationContent;