import React from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import imgfront from '../../../assets/images/planingEducation/tree.jpg';
import imgtest from '../../../assets/images/planingEducation/test.png';
import imgsql from '../../../assets/images/planingEducation/monk.jpg';
import imghr_super from '../../../assets/images/planingEducation/tree.jpg';
import imghr_2 from '../../../assets/images/planingEducation/test.png';
import { useNavigate } from "react-router-dom";

interface ITest {
    id: number;
    image: string;
    title: string;
    description: string;
    date: Date;
}

interface IRecentEducationProps {
    selectedDate: Date | undefined;
}

const RecentEducation: React.FC<IRecentEducationProps> = ({ selectedDate }) => {
    const navigate = useNavigate();

    const tests: ITest[] = [
        {
            id: 1,
            image: imghr_2,
            title: "Профессия HR",
            description: "Профессия HR",
            date: new Date(2025, 3, 20),
        },
        {
            id: 2,
            image: imghr_super,
            title: "База для HR",
            description: "Профессия HR",
            date: new Date(2025, 3, 15),
        },
        {
            id: 3,
            image: imgsql,
            title: "Структуры данных",
            description: "Профессия разработчик",
            date: new Date(2025, 3, 10),
        },
        {
            id: 4,
            image: imgfront,
            title: "Front-разработчик резюме",
            description: "Профессия разработчик",
            date: new Date(2025, 3, 25),
        },
        {
            id: 5,
            image: imgtest,
            title: "Техника продаж",
            description: "Профессия продажник",
            date: new Date(2025, 3, 5),
        },
    ];

    const filteredTests = selectedDate
        ? tests.filter(test => {
            const testDate = new Date(test.date);
            return (
                testDate.getFullYear() === selectedDate.getFullYear() &&
                testDate.getMonth() === selectedDate.getMonth()
            );
        })
        : tests;

    const handleNavigate = (id: number, title: string) => {
        navigate(`/education/create_education_content/${id}`, { state: { educationTitle: title } });
    };

    return (
        <Card>
            <CardHeader className="align-items-center d-flex justify-content-between">
                <h4 className="card-title mb-0 flex-grow-1">Обучение текущего периода</h4>
            </CardHeader>
            <CardBody>
                {filteredTests.length > 0 ? (
                    filteredTests.map(test => (
                        <div key={test.id} className="d-flex align-items-center mb-3">
                            <img
                                src={test.image}
                                alt={test.title}
                                className="rounded-circle me-3"
                                style={{ width: '50px', height: '50px' }}
                            />
                            <div className="flex-grow-1">
                                <h5
                                    className="mb-1 cursor-pointer"
                                    onClick={() => handleNavigate(test.id, test.title)}
                                >
                                    {test.title}
                                </h5>
                                <p className="text-muted mb-0">{test.description}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-muted">Нет событий обучения за выбранный период.</p>
                )}
            </CardBody>
        </Card>
    );
};

export default RecentEducation;