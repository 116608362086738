import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';

interface ModalCreateModuleProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (title: string) => void;
}

const ModalCreateModule: React.FC<ModalCreateModuleProps> = ({ isOpen, toggle, onSave }) => {
    const [moduleTitle, setModuleTitle] = useState('');
    const [titleError, setTitleError] = useState<string | null>(null);

    const handleSave = () => {
        if (!moduleTitle.trim()) {
            setTitleError('Название модуля не может быть пустым!');
            return;
        }
        onSave(moduleTitle);
        setModuleTitle('');
        setTitleError(null);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader
                className="p-3 bg-soft-primary d-flex justify-content-between align-items-center"
                toggle={toggle}
            >
                Добавить новый модуль
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="moduleTitle">Название модуля</Label>
                        <Input
                            type="text"
                            id="moduleTitle"
                            value={moduleTitle}
                            onChange={(e) => {
                                setModuleTitle(e.target.value);
                                if (titleError && e.target.value.trim()) {
                                    setTitleError(null);
                                }
                            }}
                            placeholder="Введите название модуля"
                            invalid={!!titleError}
                        />
                        {titleError && (
                            <div className="text-danger">
                                {titleError}
                            </div>
                        )}
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Добавить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalCreateModule;